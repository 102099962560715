import { graphql } from 'gatsby'
import React from 'react'

import img1 from '../../assets/blog/3/1.gif'
import img2 from '../../assets/blog/3/2.jpg'
import img3 from '../../assets/blog/3/3.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Šťastné, veselé a dobře organizované" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Šťastné, veselé a dobře organizované" />
        <ContentSubPage>
          <p>
            Tak co, jak se těšíte na nadcházející svátky? Rozzáří se vám při
            takové otázce oči jako malým dětem? Je vám to jedno? Nebo ve vás
            takový dotaz vyvolá lehký záchvat paniky? Pokud jste žena, nota bene
            matka malých dětí, je dost pravděpodobné, že c) je správně.
          </p>
          <p>
            Předvánočním stresem totiž trpí mnohem více ženy než muži. Drtivá
            většina jich například sama řeší a nakupuje dárky pro celou rodinu.
            Úklid, výzdoba, pečení, vaření, besídky, povinné večírky, udržování
            dobré nálady všech okolo. A především nutnost to všechno
            zorganizovat, rozmyslet a naplánovat. To jsou úkoly, které před
            Vánoci padnou téměř výhradně na bedra žen, které jsou už tak
            přetíženy každodenní dvojsměnnou pracovních povinností a vedením
            domácnosti, včetně výchovy dětí.
          </p>
          <p>
            Navíc se ukazuje, že skoro třetina žen právě v této době zanedbává
            věci, které jim jindy pomáhají zvýšený stres zvládat. Za normálních
            okolností ženy více než muži dbají na zdravou stravu nebo pravidelné
            cvičení. Před Vánoci jdou ale tyto dobré zvyky stranou. A co je
            horší: ženy se místo nich uchylují ke kompenzačním zlozvykům jako je
            pití alkoholu nebo přejídání.
          </p>
          <p>
            Situaci neuhlehčuje ani fakt, že nejsme příliš ochotní označit
            Vánoce za stresové období. Vánoční povinnosti? Vánoční úkoly? To
            nejsou výrazy z našeho slovníku. Tam patří spíš vánoční pohoda nebo
            radost. Takže se zdánlivě nic hrozného neděje a ten stres si nakonec
            ženy dělají samy. Faktem ale zůstává, že nadpoloviční většinu jich k
            horečnaté předvánoční činnosti motivuje strach, aby své blízké
            nezklamaly.
          </p>
          <p>
            Nějakou změnu to ovšem chtít bude. Vypadá to totiž, že vánoční stres
            není jen v našich hlavách. Projevuje se i v lékařských ordinacích.
            Mimo nespavost, bolesti hlavy, zažívací potíže a bolesti pohybového
            aparátu nebo vyšší náchylnost k infekcím postihují ženy i akutní
            psychické problémy, bolesti na hrudi či potíže s dýcháním. Bohužel
            roste i počet infarktů, který je u žen v průběhu adventu a Vánoc
            nejvyšší z celého roku.
          </p>

          <p>
            Způsobů, jak zmírňovat svou reakci na stres, je celá řada. Začněte
            tím, že si v klidu promyslíte, co je důležité a jak budete
            postupovat. Jak byste toto období chtěla prožít vy? Co můžete
            delegovat, a především, které úkoly lze vyškrtnout? Určitě jich může
            spousta skončit v přihrádce: NEJSPÍŠ NIKDY. A bude vám lépe.
            Psychicky i fyzicky.
          </p>

          <br />
          <p>Trocha inspirace k zvládání organizace vánočních příprav:</p>

          <div className="my-5">
            <img src={img1} alt="Demo animation" className="object-contain" />
          </div>
          <div className="my-5">
            <img src={img2} alt="Demo showcase" className="object-contain" />
          </div>
          <div className="my-5 flex justify-around">
            <img src={img3} alt="Demo showcase" className="object-contain" />
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
